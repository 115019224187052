.keyword-tabs {
  display: flex;
  flex-wrap: wrap;
}

.keyword-tab {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
