/* utils/styles.css */
.crop-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust this height as needed */
  background: #333;
  margin-bottom: 20px; /* Add some space below the crop area */
}

.cropper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
