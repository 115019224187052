.profile-image {
  border-radius: 50%;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
}

.customer-info {
  display: flex;
  align-items: center;
}

.customer-name {
  margin-left: 10px; /* Adjust as needed */
  font-weight: bold;
  font-size: 16px; /* Adjust as needed */
}

.shipping-address {
  font-size: 14px;
}

.shipping-address > div {
  margin-bottom: 5px;
}
