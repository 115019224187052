.progress-container {
  position: fixed;
  top: 80px; /* Adjust the top position according to your Header's height */
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f0f0f0; /* Change the background color as per your design */
  z-index: 1000; /* Ensure it's above other content */
}

.progress-bar {
  height: 100%;
  background-color: #007bff; /* Change the color as per your design */
  animation: progressAnimation 2s linear infinite; /* Adjust animation duration as needed */
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.error-container {
  position: fixed;
  top: 80px; /* Adjust top position as needed */
  left: 300px; /* Default position for larger screens */
  width: calc(100% - 300px); /* Adjust width for larger screens */
  padding: 10px 20px;   
  border-radius: 0; /* Remove border radius if not needed */
  animation: fadeInOut 0.5s ease-in-out; /* Animation for fade-in and fade-out */
  z-index: 1000; /* Ensure it's above other content */
}

/* Adjust position and width for mobile screens */
@media (max-width: 768px) {
  .error-container {
    left: 0;
    width: 100%;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error-message {
  font-size: 16px;
}
